import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Stack = makeShortcode("Stack");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "rebel-cms"
      }}>{`Rebel CMS`}</h1>
      <h2 {...{
        "id": "the-point"
      }}>{`The point`}</h2>
      <p>{`The Rebel CMS is a statically generated (`}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/docs/glossary/static-site-generator/"
        }}>{`SSG`}</a>{`) site using `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/"
        }}>{`GatsbyJS`}</a></p>
      <p>{`The core benefits of this pattern include:`}</p>
      <ul>
        <li parentName="ul">{`High performance for our customers as the pages/files can all be stored in a CDN and live at Cloud edge locations`}</li>
        <li parentName="ul">{`SEO Optimization thanks to static content that isn't relying on JavaScript or a Single Page Application pattern.`}</li>
        <li parentName="ul">{`Pages built using defined React components`}</li>
        <li parentName="ul">{`A consistent theming engine`}</li>
        <li parentName="ul">{`All pages are defined as code and living in source control`}</li>
        <li parentName="ul">{`Use of a deployment pipeline with a11y testing at build time`}</li>
        <li parentName="ul">{`A GraphQL-driven schema to fetch site data (`}<a parentName="li" {...{
            "href": "http://localhost:8000/___graphql"
          }}>{`http://localhost:8000/`}{`_`}{`_`}{`_`}{`graphql`}</a>{`)`}</li>
      </ul>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "gatsbyjs"
      }}>{`GatsbyJS`}</h2>
      <p>{`Although the GatsbyJS documentation is fantastic, there are a few key points that need some further explaining.`}</p>
      <p><em parentName="p">{`First`}</em>{` - Gatsby is a Static Site Generator that leverages our data and builds html/css versions of all our pages. This is the part that really boosts performance and SEO. Once these static assets are deployed to the cloud, they're incredibly fast to fetch from a browser.`}</p>
      <p><em parentName="p">{`Second`}</em>{` - These static pages are simply the entry point for users into the site. Once the html/css is downloaded and rendered, the rest of the code (JS) catches up and hydrates into a full React Single Page Application (SPA). This includes client-side routing (reach-router) and all other interactions. It's seamless to the user, but once the code is downloaded, you're in React world. Every subsequent page is loaded by React. Using an `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` to navigate from page to page will round-trip the user to the target static page to start this whole process over. Using a `}<inlineCode parentName="p">{`<Link>`}</inlineCode>{` provided by Gatsby will use the internal router and give a faster user experience. Do that.`}</p>
      <p>{`The last sticking point in regards to Gatsby is what it actually does under the hood. Everything is in GraphQL... but what does that even mean?`}</p>
      <p>{`The main idea is that we can use literally any data source to hold our page data. This is plastered all over the documentation. You can use the File System, a Headless CMS, REST call, SaaS, etc...`}</p>
      <p>{`So what?!`}</p>
      <p>{`So... you can fetch all this data and Gatsby magically lets you query for it? Hardly magical. Genius implementation, but not magical.`}</p>
      <p>{`All source plugins do is fetch the raw data from somewhere and use Gatsby's exports.sourceNodes hook in the gasty-node.js file to run through the data and create Nodes of a given type. By creating nodes using the createNode function (check the docs), we're given a pair of GraphQL queries for free. "allExamples" to fetch every node of that type and "example" to fetch a specific Node with query variables and matchers to filter.`}</p>
      <p>{`These Nodes are all contained within an internal, in memory GraphQL database/server/thing available to us at build/run time. This is what you see at `}<a parentName="p" {...{
          "href": "https://localhost:8000/___graphql"
        }}>{`https://localhost:8000/`}{`_`}{`_`}{`_`}{`graphql`}</a>{`.`}</p>
      <p>{`Transform plugins well expend this data. For example, `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/plugins/gatsby-transformer-json/"
        }}>{`gatsby-transformer-json`}</a>{` will take all source nodes of a `}<inlineCode parentName="p">{`.json`}</inlineCode>{` page and will parse through the code to add child nodes matching the data. This is how we can query for CMS page data.`}</p>
      <p>{`The point is the we `}<em parentName="p">{`must`}</em>{` query for this data using page queries (or static queries, check the docs) in order to get this data into the actual pages at build time.`}</p>
      <p>{`These are the steps to remember in order to keep the right mental model:`}</p>
      <ul>
        <li parentName="ul">{`Fetch the data from the source`}</li>
        <li parentName="ul">{`Creating GraphQL Nodes (and their schema) and add them to the internal data store`}</li>
        <li parentName="ul">{`Extend some of the data, as needed, using transformer plugins`}</li>
        <li parentName="ul">{`Fetch the data you need at build time for the pages you're building. This is typically done using the template components.`}</li>
      </ul>
      <Stack my={4} mdxType="Stack">
        <Note mdxType="Note">
          <p>{`Query fields missing from the schema will result in a build error. Forgetting to query for data that does exist in the schema will never get that data to your template component. These two actions are the link between sourcing the data and getting into the React code.`}</p>
        </Note>
      </Stack>
      <h3 {...{
        "id": "static-schemas"
      }}>{`Static Schemas`}</h3>
      <p>{`As all the nodes described above are created by the source data, the schema is infered by the data itself. This means we could freely add fields to our json, adjust the query fetching that data and use it in the React code.`}</p>
      <p>{`The issue that arose over time is that we needed at least one instance of every piece of data to present at all times in order to build the site properly. Otherwise, the query (and fragments) had to be modified constantly to resolve errors. Just because we don't currently have a page using certain fields today, doesn't mean we want to remove all supoort for it right away.`}</p>
      <p>{`By defining the schema statically using the createSchemaCustomization hook in `}<inlineCode parentName="p">{`gastby-node.js`}</inlineCode>{`, we can provide a consistent schema for our data. It will have to be maintained, no doubt, but this really helps contributors outside the development team by avoiding cryptic build errors. It also helps for documentation and reference.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "panel-pages"
      }}>{`Panel Pages`}</h2>
      <p>{`The vast majority of pages manually created for the Rebel and Internic sites are considered "Panel Pages" in that they're comprised of a set of individual panels or sections.`}</p>
      <p>{`The `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebel-web-cms/tree/main/src/components/panels"
        }}>{`panels`}</a>{` themselves are build with extensibility in mind and typically allow for a great deal of flexibility in how they look and what type of information they can present.`}</p>
      <p>{`All panel pages are created in a `}<inlineCode parentName="p">{`.json`}</inlineCode>{` file within the `}<inlineCode parentName="p">{`/pages`}</inlineCode>{` folder of the project and use the `}<inlineCode parentName="p">{`panel-page.js`}</inlineCode>{` `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebel-web-cms/blob/main/src/templates/panel-page.js"
        }}>{`template`}</a>{`.`}</p>
      <p>{`Using the `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/plugins/gatsby-source-filesystem/"
        }}>{`gatsby-source-filesystem`}</a>{` to pull our pages from the filesystem and the `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/plugins/gatsby-transformer-json/"
        }}>{`gatsby-transformer-json`}</a>{` to insert the .json file data into the GraphQL schema.`}</p>
      <p>{`The pages themselves are created at build time using the `}<inlineCode parentName="p">{`createPage`}</inlineCode>{` function within the `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/docs/reference/config-files/gatsby-node/"
        }}>{`gatsby-node.js file`}</a>{`.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "tld-pages"
      }}>{`TLD Pages`}</h2>
      <h3 {...{
        "id": "generic-pages"
      }}>{`Generic Pages`}</h3>
      <p>{`...`}</p>
      <h3 {...{
        "id": "custom-pages"
      }}>{`Custom Pages`}</h3>
      <p>{`...`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "markdown-pages"
      }}>{`Markdown Pages`}</h2>
      <p>{`...`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "static-schemas-1"
      }}>{`Static Schemas`}</h2>
      <p>{`...`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "static-schemas-2"
      }}>{`Static Schemas`}</h2>
      <p>{`...`}</p>
      <h3 {...{
        "id": "resolving-fields-and-schema-stitching"
      }}>{`Resolving fields and schema stitching`}</h3>
      <ul>
        <li parentName="ul">{`DynamicPrices`}</li>
      </ul>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      